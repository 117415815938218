export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const constants: any = useConstants();
  const { $apiFetch } = useNuxtApp();
  return {
    provide: {
      saveSetting: async function (body: any) {
        const url =
          config.public.apiBaseUrl +
          "/" +
          constants.routes.api.settings.save +
          "/?key=" +
          body.key +
          "&value=" +
          body.value;
        return await $apiFetch(url, {
          method: "post",
        }).then(function (response: any) {
          return response.data;
        });
      },
      getSettings: async function (body: any) {
        const url =
          config.public.apiBaseUrl +
          "/" +
          constants.routes.api.settings.get +
          "?limit=999";
        return await $apiFetch(url, {
          method: "post",
          body,
        }).then(function (response: any) {
          return response;
        });
      },
    },
  };
});

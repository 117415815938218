
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboardqGq1VqkK5VMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/dashboard.vue?macro=true";
import { default as feedbackCn5K2F1xTvMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/feedback.vue?macro=true";
import { default as loginPucXrYF1nKMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/login.vue?macro=true";
import { default as choose_45category0xYz2AiMLgMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/choose-category.vue?macro=true";
import { default as defaultZ3yP90HY18Meta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/default.vue?macro=true";
import { default as question_451OCVNCWOgLZMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-1.vue?macro=true";
import { default as question_452nzEjxP42pfMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-2.vue?macro=true";
import { default as question_453EUzo1aOuROMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-3.vue?macro=true";
import { default as thanksqNkLiyYmpuMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/thanks.vue?macro=true";
import { default as indexOPV34SmZsUMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/index.vue?macro=true";
import { default as check_45prizeDj9tQmaFKFMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/check-prize.vue?macro=true";
import { default as claim_45prizeIaDEsfrXHLMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/claim-prize.vue?macro=true";
import { default as indexn7XIan6S2OMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/index.vue?macro=true";
import { default as max_45playedD6DOBvhUYXMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/max-played.vue?macro=true";
import { default as no_45winzde8gRgC9rMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedyuosgNFUi4Meta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/prize-claimed.vue?macro=true";
import { default as winn8T5tbUkETMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/win.vue?macro=true";
import { default as wrong_45answerV7Nps7xoKWMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctk5BIzF6ARxMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/answer-correct.vue?macro=true";
import { default as beC78NVNkOgvMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/be.vue?macro=true";
import { default as congratulationsIkjw8kj00dMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/congratulations.vue?macro=true";
import { default as formMsMGRcoVMEMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/form.vue?macro=true";
import { default as loginsHPgX5rPSYMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/login.vue?macro=true";
import { default as question77TjlwOmFBMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/question.vue?macro=true";
import { default as thanksYeTn3u33sUMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/thanks.vue?macro=true";
import { default as wheelV4ARVB0GivMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardqGq1VqkK5VMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/dashboard.vue")
  },
  {
    name: "admin-feedback",
    path: "/admin/feedback",
    meta: feedbackCn5K2F1xTvMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/feedback.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginPucXrYF1nKMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45category0xYz2AiMLgMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaultZ3yP90HY18Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/default.vue")
  },
  {
    name: "feedback-question-1",
    path: "/feedback/question-1",
    meta: question_451OCVNCWOgLZMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-1.vue")
  },
  {
    name: "feedback-question-2",
    path: "/feedback/question-2",
    meta: question_452nzEjxP42pfMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-2.vue")
  },
  {
    name: "feedback-question-3",
    path: "/feedback/question-3",
    meta: question_453EUzo1aOuROMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/question-3.vue")
  },
  {
    name: "feedback-thanks",
    path: "/feedback/thanks",
    meta: thanksqNkLiyYmpuMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/feedback/thanks.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexOPV34SmZsUMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/index.vue")
  },
  {
    name: "quiz-check-prize",
    path: "/quiz/check-prize",
    meta: check_45prizeDj9tQmaFKFMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/check-prize.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prizeIaDEsfrXHLMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: indexn7XIan6S2OMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedD6DOBvhUYXMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45winzde8gRgC9rMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedyuosgNFUi4Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winn8T5tbUkETMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answerV7Nps7xoKWMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correctk5BIzF6ARxMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: beC78NVNkOgvMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulationsIkjw8kj00dMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: formMsMGRcoVMEMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginsHPgX5rPSYMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: question77TjlwOmFBMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thanksYeTn3u33sUMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheelV4ARVB0GivMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250227195536/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]